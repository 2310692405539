@import "./theme.less";

html {
  body {
    min-width: 300px;

    overlay-snacks {
      background: rgba(255, 0, 0, 0.164);
      pointer-events: none;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 1000;
      display: none;
    }

    .screen {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .mat-button-base {
      font-weight: 700;
      color: @main;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border: 0;
    }

    button {
      margin-right: 12px;

      &.full {
        .button-full();
      }

      &.highlight {
        .button-highlight();
      }
    }
  }
}
