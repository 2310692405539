@main: #007071;
@main-hover: #1b9b9b;
@blue: #5273ff;
@blue-hover: #6985ff;

.button-full() {
  background: @blue;
  border-color: transparent;
  color: white;
  transition: background 0.1s ease-in-out;

  &:hover {
    background: @blue-hover;
  }
}

.button-highlight() {
  color: #ff5273;
}
